<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div />
        <div>
          <b-button
            v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="UpdateLine()"
          >
            <font-awesome-icon
              :icon="['far', 'save']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Invoice #"
                rules="required|max:17"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Invoice #:</b></i></label>
                  <b-form-input
                    id="InvoiceNumber"
                    v-model="line.InvoiceNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Invoice Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Part Number"
                rules="max:64"
              >
                <label><i><b>Part Number:</b></i></label>
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="PartNumber"
                    v-model="line.PartNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Part Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Invoice Range Begin"
                rules="max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Invoice Range Begin:</b></i></label>
                  <b-form-input
                    id="InvRangeB"
                    v-model="line.InvRangeB"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Begin"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Invoice Range End"
                rules="max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Invoice Range End:</b></i></label>
                  <b-form-input
                    id="InvRangeE"
                    v-model="line.InvRangeE"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="End"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Special Program"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Special Program:</b></i></label>
                  <v-select
                    id="SpecialProgram"
                    v-model="line.SpecialProgram"
                    :options="SPIOptions"
                    label="text"
                    placeholder="Select the SPI"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Article Set"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Article Set:</b></i></label>
                  <v-select
                    id="ArticleSet"
                    v-model="line.ArticleSet"
                    :options="ArticleSetOptions"
                    label="text"
                    placeholder="Select the option"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Goods Description"
                rules="required|max:64"
              >
                <label class="required"><i><b>Goods Description:</b></i></label>
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <b-form-input
                    id="Description"
                    v-model="line.Description"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Goods Description"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Country of Origin"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Country of Origin:</b></i></label>
                  <v-select
                    id="COO"
                    v-model="line.COO"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Export Country:"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Export Country:</b></i></label>
                  <v-select
                    id="COE"
                    v-model="line.COE"
                    :options="CountryOptions"
                    label="country"
                    placeholder="Select the country"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Port of Loading"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Port of Loading (Foreign):</b></i></label>
                  <v-select
                    id="POL"
                    v-model="line.POL"
                    :options="ForeignPortsOptions"
                    label="foreignPOLName"
                    placeholder="Select a port"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="MID"
                rules="required|max:15"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MID #:</b></i></label>
                  <b-form-input
                    id="MID"
                    v-model="line.MID"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="MID"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Gross Weight"
                rules="required|max:10|min_value:0|integer|max_value:999999999.99|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Gross Weight (KG):</b></i></label>
                  <b-form-input
                    id="GrossWeight"
                    v-model="line.GrossWeight"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Charges Amount"
                rules="required|max:10|integer|min_value:0|max_value:999999999.99|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Charges Amount ($):</b></i></label>
                  <b-form-input
                    id="ChargesAmount"
                    v-model="line.ChargesAmount"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ruling Type"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Ruling Type:</b></i></label>
                  <v-select
                    id="RulingType"
                    v-model="line.RulingType"
                    :options="RulingOptions"
                    label="text"
                    placeholder="Select a type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ruling Number"
                :rules="`${line.RulingType && (line.RulingType.value !== '') ? 'required|max:6' : 'max:6'}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Ruling #:</b></i></label>
                  <b-form-input
                    id="RulingNumber"
                    v-model="line.RulingNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Ruling Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <label class="required"><i><b>Related Manufacturer:</b></i></label>
            <b-form-checkbox v-model="line.ManufactureRelated" />
          </b-col>
          <b-col md="3">
            <label><i><b>NAFTA Net Cost:</b></i></label>
            <b-form-checkbox v-model="line.IsNAFTANetCost" />
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="License Type"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>License Type:</b></i></label>
                  <v-select
                    id="LicenseType"
                    v-model="line.LicenseType"
                    :options="LicensesOptions"
                    label="text"
                    placeholder="Select the license"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="License"
                :rules="`${line.LicenseType && (line.LicenseType.value !== '') ? 'required|max:10' : 'max:10'}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>License #:</b></i></label>
                  <b-form-input
                    id="License"
                    v-model="line.License"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="License"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="VISA"
                rules="max:9"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>VISA #:</b></i></label>
                  <b-form-input
                    id="VISANumber"
                    v-model="line.VISANumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="VISA Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Product Claim Code"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Product Claim Code:</b></i></label>
                  <v-select
                    id="ProductClaimCode"
                    v-model="line.ProductClaimCode"
                    :options="ProductClaimOptions"
                    label="text"
                    placeholder="Select the code"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Textile Code"
                rules="max:3"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Textile Code:</b></i></label>
                  <b-form-input
                    id="TextileCateCode"
                    v-model="line.TextileCateCode"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Textile Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="FTZ Status"
                rules=""
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>FTZ Status:</b></i></label>
                  <v-select
                    id="FTZStatus"
                    v-model="line.FTZStatus"
                    :options="FTZOptions"
                    label="text"
                    placeholder="Select the status"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="FTZ Date"
                :rules="`${line.FTZStatus && (line.FTZStatus.value !== '') ? 'required' : ''}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>FTZ Date:</b></i></label>
                  <b-form-datepicker
                    v-model="line.FTZDate"
                    placeholder="MM/DD/YYYY"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                    locale="en"
                    :reset-button="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="FTZ Qty"
                :rules="`${line.FTZStatus && (line.FTZStatus.value !== '') ? 'required|min_value:0|max_value:99999999.99' : 'min_value:0|max_value:99999999.99'}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>FTZ Qty:</b></i></label>
                  <b-form-input
                    id="FTZQty"
                    v-model="line.FTZQty"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button-toolbar justify>
          <div />
          <div>
            <b-button
              v-b-tooltip.hover="'Update in Database (Does not update in CBP system)'"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon mb-1"
              @click="UpdateLine()"
            >
              <font-awesome-icon
                :icon="['far', 'save']"
                size="lg"
              />
            </b-button>
          </div>
        </b-button-toolbar>
        <b-row>
          <b-col md="12">
            <b-tabs>
              <b-tab
                ref="TariffTab"
                title="Tariff"
              >
                <AgGrid
                  ref="TariffGrid"
                  :grid="aggrid"
                  @LoadAll="LoadAll"
                >
                  <template v-slot:lftbtn>
                    <b-button
                      v-b-tooltip.hover="'Create new tariff'"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      class="btn-icon mb-1"
                      @click="CreateNewTariff()"
                    >
                      <font-awesome-icon
                        :icon="['far', 'plus-square']"
                        size="lg"
                      />
                    </b-button>
                  </template>

                </AgGrid>
              </b-tab>

              <b-tab
                ref="ADCVDTab"
                title="AD/CVD"
              >
                <validation-observer
                  ref="adcvdRules"
                  tag="form"
                >
                  <div class="divider my-2">
                    <div class="divider-text">
                      AD
                    </div>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="AD Case Number"
                          rules="max:10"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>AD Case #:</b></i></label>
                            <b-form-input
                              id="ADCaseNumber"
                              v-model="line.ADCaseNumber"
                              v-uppercase
                              :state="errors.length > 0 ? false:null"
                              placeholder="AD Case Number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Bond/Cash"
                          :rules="`${line.ADCaseNumber && (line.ADCaseNumber !== '') ? 'required' : ''}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Bond/Cash:</b></i></label>
                            <v-select
                              id="ADBondOrCash"
                              v-model="line.ADBondOrCash"
                              :options="BondCaseOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="AD Rate Type"
                          :rules="`${line.ADCaseNumber && (line.ADCaseNumber !== '') ? 'required' : ''}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>AD Rate Type:</b></i></label>
                            <v-select
                              id="ADRateType"
                              v-model="line.ADRateType"
                              :options="RateOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Non-Reimbursement Declaration Identifier"
                          rules="max:10"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Non-Reimbursement Declaration Identifier:</b></i></label>
                            <b-form-input
                              id="ADNonReimburseDecl"
                              v-model="line.ADNonReimburseDecl"
                              v-uppercase
                              :state="errors.length > 0 ? false:null"
                              placeholder="Identifier"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="AD Qty"
                          :rules="`${line.ADCaseNumber && (line.ADCaseNumber !== '') ? 'required|min_value:0|max_value:99999999.99' : 'min_value:0|max_value:99999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>AD Qty:</b></i></label>
                            <b-form-input
                              id="ADQty"
                              v-model="line.ADQty"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="AD Amount"
                          :rules="`${line.ADCaseNumber && (line.ADCaseNumber !== '') ? 'required|min_value:0|max_value:999999999|integer' : 'min_value:0|max_value:999999999|integer'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>AD Amount:</b></i></label>
                            <b-form-input
                              id="ADAmount"
                              v-model="line.ADAmount"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="AD Deposit Rate"
                          :rules="`${line.ADCaseNumber && (line.ADCaseNumber !== '') ? 'required|min_value:0|max_value:99999999.99' : 'min_value:0|max_value:99999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>AD Deposit Rate:</b></i></label>
                            <b-form-input
                              id="ADDepositRate"
                              v-model="line.ADDepositRate"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="AD Duty"
                          :rules="`${line.ADCaseNumber && (line.ADCaseNumber !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>AD Duty:</b></i></label>
                            <b-form-input
                              id="ADDuty"
                              v-model="line.ADDuty"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="divider my-2">
                    <div class="divider-text">
                      CVD
                    </div>
                  </div>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="CVD Case Number"
                          rules="max:10"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>CVD Case #:</b></i></label>
                            <b-form-input
                              id="CVDCaseNumber"
                              v-model="line.CVDCaseNumber"
                              v-uppercase
                              :state="errors.length > 0 ? false:null"
                              placeholder="CVD Case Number"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Bond/Cash"
                          :rules="`${line.CVDCaseNumber && (line.CVDCaseNumber !== '') ? 'required' : ''}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Bond/Cash:</b></i></label>
                            <v-select
                              id="CVDBondOrCash"
                              v-model="line.CVDBondOrCash"
                              :options="BondCaseOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="CVD Rate Type"
                          :rules="`${line.CVDCaseNumber && (line.CVDCaseNumber !== '') ? 'required' : ''}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>CVD Rate Type:</b></i></label>
                            <v-select
                              id="CVDRateType"
                              v-model="line.CVDRateType"
                              :options="RateOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Non-Reimbursement Declaration Identifier"
                          rules="max:10"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Non-Reimbursement Declaration Identifier:</b></i></label>
                            <b-form-input
                              id="CVDNonReimburseDecl"
                              v-model="line.CVDNonReimburseDecl"
                              v-uppercase
                              :state="errors.length > 0 ? false:null"
                              placeholder="Identifier"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="CVD Qty"
                          :rules="`${line.CVDCaseNumber && (line.CVDCaseNumber !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>CVD Qty:</b></i></label>
                            <b-form-input
                              id="CVDQty"
                              v-model="line.CVDQty"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="CVD Amount"
                          :rules="`${line.CVDCaseNumber && (line.CVDCaseNumber !== '') ? 'required|min_value:0|max_value:999999999|integer' : 'min_value:0|max_value:999999999|integer'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>CVD Amount:</b></i></label>
                            <b-form-input
                              id="CVDAmount"
                              v-model="line.CVDAmount"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="CVD Deposit Rate"
                          :rules="`${line.CVDCaseNumber && (line.CVDCaseNumber !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>CVD Deposit Rate:</b></i></label>
                            <b-form-input
                              id="CVDDepositRate"
                              v-model="line.CVDDepositRate"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="CVD Duty"
                          :rules="`${line.CVDCaseNumber && (line.CVDCaseNumber !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>CVD Duty:</b></i></label>
                            <b-form-input
                              id="CVDDuty"
                              v-model="line.CVDDuty"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
              <b-tab
                ref="FeesTab"
                title="Fees"
              >
                <validation-observer
                  ref="feesRules"
                  tag="form"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="MPF"
                          rules="required|min_value:0|max_value:999999999.99"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class="required"><i><b>MPF ($):</b></i></label>
                            <b-form-input
                              id="MPF"
                              v-model="line.MPF"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="HMF"
                          rules="required|min_value:0|max_value:999999999.99"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class="required"><i><b>HMF ($):</b></i></label>
                            <b-form-input
                              id="HMF"
                              v-model="line.HMF"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Tax Code"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Tax Code:</b></i></label>
                            <v-select
                              id="TaxCode"
                              v-model="line.TaxCode"
                              :options="TaxOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Tax Amount"
                          :rules="`${line.TaxCode && (line.TaxCode.value !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Tax Amount ($):</b></i></label>
                            <b-form-input
                              id="TaxAmount"
                              v-model="line.TaxAmount"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Other Rev Code"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Other Rev Code:</b></i></label>
                            <v-select
                              id="OtherRevCode"
                              v-model="line.OtherRevCode"
                              :options="OtherFeesOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="3">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Other Rev Amount"
                          :rules="`${line.OtherRevCode && (line.OtherRevCode.value !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Other Rev Amount ($):</b></i></label>
                            <b-form-input
                              id="OtherRevAmount"
                              v-model="line.OtherRevAmount"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Additional Fee Code"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Additional Fee Code:</b></i></label>
                            <v-select
                              id="OtherFeeCode"
                              v-model="line.OtherFeeCode"
                              :options="LineUserFeeOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Fee Amount"
                          :rules="`${line.OtherFeeCode && (line.OtherFeeCode.value !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Fee Amount ($):</b></i></label>
                            <b-form-input
                              id="OtherFee"
                              v-model="line.OtherFee"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Additional Fee Code 1"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Additional Fee Code 1:</b></i></label>
                            <v-select
                              id="OtherFeeCode1"
                              v-model="line.OtherFeeCode1"
                              :options="LineUserFeeOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Fee Amount 1"
                          :rules="`${line.OtherFeeCode1 && (line.OtherFeeCode1.value !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Fee Amount 1 ($):</b></i></label>
                            <b-form-input
                              id="OtherFee1"
                              v-model="line.OtherFee1"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Additional Fee Code 2"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Additional Fee Code 2:</b></i></label>
                            <v-select
                              id="OtherFeeCode2"
                              v-model="line.OtherFeeCode2"
                              :options="LineUserFeeOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Fee Amount 2"
                          :rules="`${line.OtherFeeCode2 && (line.OtherFeeCode2.value !== '') ? 'required|min_value:0|max_value:999999999.99' : 'min_value:0|max_value:999999999.99'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>Fee Amount 2 ($):</b></i></label>
                            <b-form-input
                              id="OtherFee2"
                              v-model="line.OtherFee2"
                              type="number"
                              min="0"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
              <b-tab
                ref="AddlDeclTab"
                title="Addl Declaration"
              >
                <validation-observer
                  ref="addldeclRules"
                  tag="form"
                >
                  <div class="divider my-2">
                    <div class="divider-text">
                      <b-form-checkbox v-model="line.LumberExport">
                        Lumber Export Exclusion
                      </b-form-checkbox>
                    </div>
                  </div>
                  <b-row v-if="line.LumberExport">
                    <b-col md="2">
                      <label :class="`${line.LumberExport ? 'required' : ''}`"><i><b>Lumber Act of 2008:</b></i></label>
                      <b-form-checkbox v-model="line.MeetLumberAct" />
                    </b-col>
                    <b-col md="5">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Lumber Export Price"
                          :rules="`${line.LumberExport ? 'required|max:10|integer' : 'max:10|integer'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label :class="`${line.LumberExport ? 'required' : ''}`"><i><b>Lumber Export price ($):</b></i></label>
                            <b-form-input
                              id="LumberExportPrice"
                              v-model="line.LumberExportPrice"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="5">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Lumber Export Charge"
                          :rules="`${line.LumberExport ? 'max:10|integer' : 'max:10|integer'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label :class="`${line.LumberExport ? 'required' : ''}`"><i><b>Lumber Export Charges ($):</b></i></label>
                            <b-form-input
                              id="LumberExportCharge"
                              v-model="line.LumberExportCharge"
                              type="number"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="divider my-2">
                    <div class="divider-text">
                      <b-form-checkbox v-model="line.SteelProduct">
                        Steel Export Exclusion
                      </b-form-checkbox>
                    </div>
                  </div>
                  <b-row v-if="line.SteelProduct">
                    <b-col md="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Steel Export Exclusion"
                          :rules="`${line.SteelProduct ? 'required|max:9' : 'max:9'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label :class="`${line.SteelProduct ? 'required' : ''}`"><i><b>Steel Export Exclusion ID:</b></i></label>
                            <b-form-input
                              id="SteelExclusion"
                              v-model="line.SteelExclusion"
                              v-uppercase
                              placeholder="Exclusion Identifier"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="divider my-2">
                    <div class="divider-text">
                      <b-form-checkbox v-model="line.AluminumProduct">
                        Aluminum Export Exclusion
                      </b-form-checkbox>
                    </div>
                  </div>
                  <b-row v-if="line.AluminumProduct">
                    <b-col md="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="Aluminum Export Exclusion"
                          :rules="`${line.AluminumProduct ? 'required|max:9' : 'max:9'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label :class="`${line.AluminumProduct ? 'required' : ''}`"><i><b>Aluminum Export Exclusion ID:</b></i></label>
                            <b-form-input
                              id="AluminumExclusion"
                              v-model="line.AluminumExclusion"
                              v-uppercase
                              placeholder="Exclusion Identifier"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <div class="divider my-2">
                    <div class="divider-text">
                      <b-form-checkbox v-model="line.KRSteelCert">
                        KR Steel Export Exclusion
                      </b-form-checkbox>
                    </div>
                  </div>
                  <b-row v-if="line.KRSteelCert">
                    <b-col md="4">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="KOSA Number"
                          :rules="`${line.KRSteelCert ? 'required|max:9' : 'max:9'}`"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label :class="`${line.KRSteelCert ? 'required' : ''}`"><i><b>KOSA Number:</b></i></label>
                            <b-form-input
                              id="KOSANumber"
                              v-model="line.KOSANumber"
                              v-uppercase
                              placeholder="Exclusion Identifier"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
              <b-tab
                ref="CWTab"
                title="Census Warning"
              >
                <validation-observer
                  ref="cwRules"
                  tag="form"
                >
                  <b-tabs>
                    <b-tab title="Override">
                      <b-button-toolbar justify>
                        <div />
                        <div>
                          <b-button
                            v-b-tooltip.hover="'Update CW in CBP'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            @click="SendCWOverride()"
                          ><font-awesome-icon
                             class="mr-50"
                             :icon="['far', 'paper-plane']"
                             size="lg"
                           />
                            <span class="align-middle">Send CW Override</span></b-button>
                        </div>
                      </b-button-toolbar>
                      <b-row>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="Census Condition Code"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>Census Condition Code:</b></i></label>
                                <v-select
                                  id="CWConditionCode"
                                  v-model="line.CWConditionCode"
                                  :options="CWOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="CW Override"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>CW Override:</b></i></label>
                                <v-select
                                  id="CWOverride"
                                  v-model="line.CWOverride"
                                  :options="CWOverrideOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="Census Condition Code 1"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>Census Condition Code 1:</b></i></label>
                                <v-select
                                  id="CWConditionCode1"
                                  v-model="line.CWConditionCode1"
                                  :options="CWOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="CW Override 1"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>CW Override 1:</b></i></label>
                                <v-select
                                  id="CWOverride1"
                                  v-model="line.CWOverride1"
                                  :options="CWOverrideOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="Census Condition Code 2"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>Census Condition Code 2:</b></i></label>
                                <v-select
                                  id="CWConditionCode2"
                                  v-model="line.CWConditionCode2"
                                  :options="CWOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="CW Override 2"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>CW Override 2:</b></i></label>
                                <v-select
                                  id="CWOverride2"
                                  v-model="line.CWOverride2"
                                  :options="CWOverrideOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="Census Condition Code 3"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>Census Condition Code 3:</b></i></label>
                                <v-select
                                  id="CWConditionCode3"
                                  v-model="line.CWConditionCode3"
                                  :options="CWOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="CW Override 3"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>CW Override 3:</b></i></label>
                                <v-select
                                  id="CWOverride3"
                                  v-model="line.CWOverride3"
                                  :options="CWOverrideOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="Census Condition Code 4"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>Census Condition Code 4:</b></i></label>
                                <v-select
                                  id="CWConditionCode4"
                                  v-model="line.CWConditionCode4"
                                  :options="CWOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <b-col md="6">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="CW Override 4"
                            >
                              <b-form-group
                                :state="errors.length > 0 ? false:null"
                              >
                                <label class=""><i><b>CW Override 4:</b></i></label>
                                <v-select
                                  id="CWOverride4"
                                  v-model="line.CWOverride4"
                                  :options="CWOverrideOptions"
                                  label="text"
                                  placeholder="Select the option"
                                />
                                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                  {{ errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-tab>
                    <b-tab
                      v-if="CWResponse"
                      title="Override Status"
                    >
                      <b-button-toolbar justify>
                        <div />
                        <div>
                          <b-button
                            v-b-tooltip.hover="'Refresh notification'"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            class="btn-icon"
                            @click="LoadCWResponse()"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'sync']"
                              size="lg"
                            />
                          </b-button>
                        </div>
                      </b-button-toolbar>
                      <b-row class="mt-1">
                        <b-col md="12">
                          <b-table
                            v-if="CWResponse"
                            :fields="fields"
                            :items="CWResponse"
                            responsive=""
                            hover
                          >
                            <template #cell(cwCode)="data">
                              {{ data.item.cwCode }}
                            </template>
                            <template #cell(cwOverrideCode)="data">
                              {{ data.item.cwOverrideCode }}
                            </template>
                            <template #cell(cwCondition)="data">
                              {{ data.item.cwCondition }}
                            </template>
                            <template #cell(narrativeText)="data">
                              {{ data.item.narrativeText }}
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-tab>
                  </b-tabs>

                </validation-observer>
              </b-tab>
              <b-tab
                ref="PSCTab"
                title="PSC"
              >
                <validation-observer
                  ref="pscRules"
                  tag="form"
                >
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="PSC1"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>PSC1:</b></i></label>
                            <v-select
                              id="PSC1"
                              v-model="line.PSC1"
                              :options="PSCLineOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="PSC2"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>PSC2:</b></i></label>
                            <v-select
                              id="PSC2"
                              v-model="line.PSC2"
                              :options="PSCLineOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="PSC3"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>PSC3:</b></i></label>
                            <v-select
                              id="PSC3"
                              v-model="line.PSC3"
                              :options="PSCLineOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="PSC4"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>PSC4:</b></i></label>
                            <v-select
                              id="PSC4"
                              v-model="line.PSC4"
                              :options="PSCLineOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <b-form-group>
                        <validation-provider
                          #default="{ errors }"
                          name="PSC5"
                        >
                          <b-form-group
                            :state="errors.length > 0 ? false:null"
                          >
                            <label class=""><i><b>PSC5:</b></i></label>
                            <v-select
                              id="PSC5"
                              v-model="line.PSC5"
                              :options="PSCLineOptions"
                              label="text"
                              placeholder="Select the option"
                            />
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                              {{ errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </validation-observer>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>

    <b-modal
      id="CreateTariffModal"
      ref="CreateTariff"
      title="New Tariff"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="tariffRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="HTS #"
                rules="required|max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>HTS #:</b></i></label>
                  <b-form-input
                    id="NewHTS"
                    v-model="tariff.HTSNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="HTS Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Duty"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Duty:</b></i></label>
                  <b-form-input
                    id="Duty"
                    v-model="tariff.Duty"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Value"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Invoiced Value ($):</b></i></label>
                  <b-form-input
                    id="NewValue"
                    v-model="tariff.Value"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Value"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MMV ($)(+):</b></i></label>
                  <b-form-input
                    id="NewMMV"
                    v-model="tariff.MMV"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="NewDC"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>NDC ($)(-):</b></i></label>
                  <b-form-input
                    id="NDC"
                    v-model="tariff.NDC"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Entered Value"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Entered Value ($):</b></i></label>
                  <b-form-input
                    id="NewEnteredValue"
                    v-model="tariff.EnteredValue"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Qty:</b></i></label>
                  <b-form-input
                    id="NewQty"
                    v-model="tariff.Qty"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="UOM"
                :rules="`${tariff.HTSNumber.substring(0,2) == '99' || tariff.HTSNumber.substring(0,2) == '98' ? '' : 'required' }`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>UOM:</b></i></label>
                  <v-select
                    id="NewUOM"
                    v-model="tariff.UOM"
                    :options="UOMOptions"
                    label="codeName"
                    placeholder="Select the uom"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty 2"
                rules="min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Qty 2:</b></i></label>
                  <b-form-input
                    id="NewQty1"
                    v-model="tariff.Qty1"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="UOM 2"
                :rules="`${line.Qty1 && (line.Qty1 !== '0') ? 'required' : ''}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>UOM 2:</b></i></label>
                  <v-select
                    id="NewUOM1"
                    v-model="tariff.UOM1"
                    :options="UOMOptions"
                    label="codeName"
                    placeholder="Select the uom"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty 3"
                rules="min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Qty 3:</b></i></label>
                  <b-form-input
                    id="NewQty2"
                    v-model="tariff.Qty2"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="UOM 3"
                :rules="`${line.Qty2 && (line.Qty2 !== '0') ? 'required' : ''}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>UOM 3:</b></i></label>
                  <v-select
                    id="NewUOM2"
                    v-model="tariff.UOM2"
                    :options="UOMOptions"
                    label="codeName"
                    placeholder="Select the uom"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewTariff()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>

    </b-modal>

    <b-modal
      id="ViewTariffModal"
      ref="ViewTariff"
      title="Tariff Detail"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="tariffRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="HTS #"
                rules="required|max:10"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>HTS #:</b></i></label>
                  <b-form-input
                    id="HTS"
                    v-model="tariff.HTSNumber"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="HTS Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Duty"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Duty:</b></i></label>
                  <b-form-input
                    id="Duty"
                    v-model="tariff.Duty"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Value"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Invoiced Value ($):</b></i></label>
                  <b-form-input
                    id="Value"
                    v-model="tariff.Value"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Value"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>MMV ($)(+):</b></i></label>
                  <b-form-input
                    id="MMV"
                    v-model="tariff.MMV"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="NDC"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>NDC ($)(-):</b></i></label>
                  <b-form-input
                    id="NDC"
                    v-model="tariff.NDC"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Entered Value"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Entered Value ($):</b></i></label>
                  <b-form-input
                    id="EnteredValue"
                    v-model="tariff.EnteredValue"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty"
                rules="required|min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Qty:</b></i></label>
                  <b-form-input
                    id="Qty"
                    v-model="tariff.Qty"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="UOM"
                :rules="`${tariff.HTSNumber.substring(0,2) == '99' || tariff.HTSNumber.substring(0,2) == '98' ? '' : 'required' }`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>UOM:</b></i></label>
                  <v-select
                    id="UOM"
                    v-model="tariff.UOM"
                    :options="UOMOptions"
                    label="codeName"
                    placeholder="Select the uom"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty 2"
                rules="min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Qty 2:</b></i></label>
                  <b-form-input
                    id="Qty1"
                    v-model="tariff.Qty1"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="UOM 2"
                :rules="`${line.Qty1 && (line.Qty1 !== '0') ? 'required' : ''}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>UOM 2:</b></i></label>
                  <v-select
                    id="UOM1"
                    v-model="tariff.UOM1"
                    :options="UOMOptions"
                    label="codeName"
                    placeholder="Select the uom"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty 3"
                rules="min_value:0|max_value:999999999.99"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>Qty 3:</b></i></label>
                  <b-form-input
                    id="Qty2"
                    v-model="tariff.Qty2"
                    type="number"
                    min="0"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="UOM 3"
                :rules="`${line.Qty2 && (line.Qty2 !== '0') ? 'required' : ''}`"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class=""><i><b>UOM 3:</b></i></label>
                  <v-select
                    id="UOM2"
                    v-model="tariff.UOM2"
                    :options="UOMOptions"
                    label="codeName"
                    placeholder="Select the uom"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateTariff()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>

    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABILineTariffViewDelBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'EsLineDetail',
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      esid: this.$route.params.esId,
      lineid: this.$route.params.lineid,
      lineIdentifier: '0',
      TempLineId: '',
      TempTariffId: '',
      aggrid: [],
      ProductClaimOptions: [
        { text: 'Craft Beverage Modernization Act Refund Claim (C)', value: 'C' },
        { text: 'A folklore product (F)', value: 'F' },
        { text: 'A made to measure suit (G)', value: 'G' },
        { text: 'Certain garments in chapter 61 or 62 (H)', value: 'H' },
        { text: 'A textile fashion sample (M)', value: 'M' },
      ],
      ArticleSetOptions: [
        { text: 'Header Article (X)', value: 'X' },
        { text: 'Component Article (V)', value: 'V' },
      ],
      RulingOptions: [
        { text: 'Pre-Classification Number (C)', value: 'C' },
        { text: 'Pre-Approval number (P)', value: 'P' },
        { text: 'Binding Ruling number (R)', value: 'R' },
      ],
      SPIOptions: [
        { text: 'GSP (A)', value: 'A' },
        { text: 'Australia Free Trade Agreement (AU)', value: 'AU' },
        { text: 'Automotive Product Trade Act (B)', value: 'B' },
        { text: 'Automotive Product Trade Act + FTA MPF Exemption (B#)', value: 'B#' },
        { text: 'Bahrain Free Trade Agreement (BH)', value: 'BH' },
        { text: 'Agreement on Trade in Civil Aircraft (C)', value: 'C' },
        { text: 'Agreement on Trade in Civil Aircraft + FTA MPF Exemption (C#)', value: 'C#' },
        { text: 'NAFTA-Canada (CA)', value: 'CA' },
        { text: 'Chile Free Trade Act (CL)', value: 'CL' },
        { text: 'Columbia Trade Promotion Agreement (CO)', value: 'CO' },
        { text: 'AGOA (D)', value: 'D' },
        { text: 'CBERA/CBI (E)', value: 'E' },
        { text: 'U.S. – Israel Free Trade Area Agreement (IL)', value: 'IL' },
        { text: 'ATPA (J)', value: 'J' },
        { text: 'ATPDEA (J+)', value: 'J+' },
        { text: 'Jordan Free Trade Agreement (JO)', value: 'JO' },
        { text: 'U.S. – Japan Trade Agreement (JP)', value: 'JP' },
        { text: 'Agreement on Trade in Pharmaceutical Products (K)', value: 'K' },
        { text: 'Agreement on Trade in Pharmaceutical Products + FTA MPF Exemption (K#)', value: 'K#' },
        { text: 'U.S. – Korea Free Trade Agreement (KR)', value: 'KR' },
        { text: 'Uruguay Round Concessions on Intermediate Chemicals for Dyes (L)', value: 'L' },
        { text: 'Uruguay Round concessions on Intermediate Chemicals for Dyes + FTA MPF Exemption (L#)', value: 'L#' },
        { text: 'Morocco Free Trade Agreement (MA)', value: 'MA' },
        { text: 'NAFTA-Mexico (MX)', value: 'MX' },
        { text: 'Wetbank/GZ/Jordan/Israel (N)', value: 'N' },
        { text: 'Nepal Preference Program (NP)', value: 'NP' },
        { text: 'Oman Free Trade Agreement (OM)', value: 'OM' },
        { text: 'Originating CAFTA (P)', value: 'P' },
        { text: 'Panama Trade Promotion Agreement (PA)', value: 'PA' },
        { text: 'Peru Trade Promotion Agreement (PE)', value: 'PE' },
        { text: 'Qualifying CAFTA (P+)', value: 'P+' },
        { text: 'CBTPA (R)', value: 'R' },
        { text: 'USMCA (S)', value: 'S' },
        { text: 'Qualifiying USMCA (A)', value: 'S+' },
        { text: 'Singapore Free Trade Act (SG)', value: 'SG' },
        { text: 'Product of PR or CBERA (W)', value: 'W' },
        { text: 'U.S. Insular Possession (Y)', value: 'Y' },
        { text: 'Freely Associated States (Z)', value: 'Z' },
      ],
      LineUserFeeOptions: [
        { text: 'Beef Fee (053)', value: '053' },
        { text: 'Pork Fee (054)', value: '054' },
        { text: 'Honey Fee (055)', value: '055' },
        { text: 'Cotton Fee (056)', value: '056' },
        { text: 'Raspberry Fee (057)', value: '057' },
        { text: 'Sugar Fee (079)', value: '079' },
        { text: 'Potato Fee (090)', value: '090' },
        { text: 'Lime Fee (102)', value: '102' },
        { text: 'Mushroom Fee (103)', value: '103' },
        { text: 'Watermelon Fee (104)', value: '104' },
        { text: 'Softwood Lumber Fee (105)', value: '105' },
        { text: 'Blueberry Fee (106)', value: '106' },
        { text: 'Avocado Fee (107)', value: '107' },
        { text: 'Mango Fee (108)', value: '108' },
        { text: 'Sorghum Fee (109)', value: '109' },
        { text: 'Dairy Product Fee (110)', value: '110' },
        { text: 'Formal MPF (499)', value: '499' },
        { text: 'HMF (501)', value: '501' },
      ],
      CWOverrideOptions: [
        { text: 'Exception to Embargo (01)', value: '01' },
        { text: 'Timing of Embargo (02)', value: '02' },
        { text: 'Country Verified as Correct (03)', value: '03' },
        { text: 'Prototype (04)', value: '04' },
        { text: 'Sample (05)', value: '05' },
        { text: 'Assist (06)', value: '06' },
        { text: 'One-of-a-kind (07)', value: '07' },
        { text: 'Precision Made (08)', value: '08' },
        { text: 'Unique Material (09)', value: '09' },
        { text: 'Experimental Drug (10)', value: '10' },
        { text: 'Military Application (11)', value: '11' },
        { text: 'Mass Produced (12)', value: '12' },
        { text: 'Less Than Perfect (13)', value: '13' },
        { text: 'Lower Quality Material (14)', value: '14' },
        { text: 'Market Conditions (15)', value: '15' },
        { text: 'Special Handling Required (16)', value: '16' },
        { text: 'Chartered Transportation (17)', value: '17' },
        { text: 'Insurance Costs Very High (18)', value: '18' },
        { text: 'Rush Delivery (19)', value: '19' },
        { text: 'Weight of Article Heavier Than Normal (20)', value: '20' },
        { text: 'Weight of Article Lighter Than Normal (21)', value: '21' },
        { text: 'Packaging Heavier Than Normal (22)', value: '22' },
        { text: 'Packaging Lighter Than Normal (23)', value: '23' },
        { text: 'Non-product Line Item Needed to Conduct Business (24)', value: '24' },
        { text: 'Beginning to Import New Product Line (25)', value: '25' },
        { text: 'Country of Export Verified as Correct (26)', value: '26' },
        { text: 'FTZ Withdrawal Low Foreign Value (27)', value: '27' },
        { text: 'Parameter Change Request Pending (49)', value: '49' },
        { text: 'Correct as Entered (50)', value: '50' },
        { text: 'Entered Under Special Conditions (51)', value: '51' },
      ],
      CWOptions: [
        { text: 'IMPROBABLE COUNTRY (27A)', value: '27A' },
        { text: 'MAXIMUM VALUE EXCEEDED (27P)', value: '27P' },
        { text: 'QTY1/QTY2 (27B)', value: '27B' },
        { text: 'QTY2/QTY1 (27M)', value: '27M' },
        { text: 'OR-HI VAL/QTY (1) (27D)', value: '27D' },
        { text: 'OR-HI VAL/QTY (2) (27F)', value: '27F' },
        { text: 'OR-LO VAL/QTY (1) (27C)', value: '27C' },
        { text: 'OR-LO VAL/QTY (2) (28E)', value: '28E' },
        { text: 'IMPROBABLE AIR TARIFF (27G)', value: '27G' },
        { text: 'GROSS WEIGHT - VESSEL (27I)', value: '27I' },
        { text: 'GROSS WEIGHT - AIR (27H)', value: '27H' },
        { text: 'MAXIMUM CHARGES EXCEEDED (27Q)', value: '27Q' },
        { text: 'OR-AGR CHARGES/VALUE (27J)', value: '27J' },
      ],
      FTZOptions: [
        { text: 'Privileged Foreign (P)', value: 'P' },
        { text: 'Non-Privileged Foreign (N)', value: 'N' },
        { text: 'Domestic (D)', value: 'D' },
      ],
      LicensesOptions: [
        { text: 'Steel Import License (01)', value: '01' },
        { text: 'Singapore TPL Certificate (02)', value: '02' },
        { text: 'Canadian NAFTA TPL Certificate (03)', value: '03' },
        { text: 'Mexican NAFTA TPL Certificate (04)', value: '04' },
        { text: 'Beef Export Certificate (05)', value: '05' },
        { text: 'Diamond Certificate (06)', value: '06' },
        { text: 'ATPDEA Certificate (07)', value: '07' },
        { text: 'Australia Free Trade Export Certificate (08)', value: '08' },
        { text: 'Mexican Cement Import License (09)', value: '09' },
        { text: 'CAFTA TPL Certificate (10)', value: '10' },
        { text: 'ALB Certificate (11)', value: '11' },
        { text: 'Cotton Shirting Fabric License (12)', value: '12' },
        { text: 'Haiti Earned Import Allowance (13)', value: '13' },
        { text: 'Agricultural License (14)', value: '14' },
        { text: 'Canadian Export Sugar Certificate (16)', value: '16' },
        { text: 'Wool License (17)', value: '17' },
        { text: 'CBTPA Certification (18)', value: '18' },
        { text: 'AGOA Textile Provision Number (19)', value: '19' },
        { text: 'USDA Sugar Certificate (21)', value: '21' },
        { text: 'Organic Product Exemption Certificate (22)', value: '22' },
        { text: 'AMS Certificate of Exemption (23)', value: '23' },
        { text: 'Dominican Republic Earned Allowance Program Certificate (25)', value: '25' },
        { text: 'Mexican Sugar Export License (26)', value: '26' },
        { text: 'General Note 15 (c) Waiver Certificate (27)', value: '27' },
        { text: 'Aluminum Import License (28)', value: '28' },
        { text: 'Canadian USMCA TPL Certificate (29)', value: '29' },
        { text: 'Mexican USMCA TPL Certificate (30)', value: '30' },
      ],
      BondCaseOptions: [
        { text: 'Bond (B)', value: 'B' },
        { text: 'Cash (C)', value: 'C' },
      ],
      RateOptions: [
        { text: 'Ad Valorem (A)', value: 'A' },
        { text: 'Specific (S)', value: 'S' },
      ],
      ImporterAdditionalOptions: [
        { text: 'Softwood Lumber Export Information (01)', value: '01' },
        { text: 'Steel products (02)', value: '02' },
        { text: 'Aluminum products (03)', value: '03' },
        { text: 'KRExport Steel Certificate (04)', value: '04' },
      ],
      TaxOptions: [
        { text: 'Distilled Spirits (016)', value: '016' },
        { text: 'Wines (017)', value: '017' },
        { text: 'Tobacco Products (018)', value: '018' },
        { text: 'Other (022)', value: '022' },
      ],
      OtherFeesOptions: [
        { text: 'Coffee Imports (672)', value: '672' },
      ],
      PSCLineOptions: [
        { text: 'Invoice (L01)', value: 'L01' },
        { text: 'SPI (L02)', value: 'L02' },
        { text: 'Classification (L03)', value: 'L03' },
        { text: 'Value (L04)', value: 'L04' },
        { text: 'Non-Dutiable Charges (L05)', value: 'L05' },
        { text: 'Duty (L06)', value: 'L06' },
        { text: 'COO (L07)', value: 'L07' },
        { text: 'COE (L08)', value: 'L08' },
        { text: 'AD/CVD Case-Critical Circumstances (L09)', value: 'L09' },
        { text: 'AD/CVD Case-Party (L10)', value: 'L10' },
        { text: 'AD/CVD Case-Rate (L11)', value: 'L11' },
        { text: 'AD/CVD Case-Scope (L12)', value: 'L12' },
        { text: 'AD/CVD Case-Non-Reimbursement Statement (L13)', value: 'L13' },
        { text: 'Ruling (L14)', value: 'L14' },
        { text: 'PGA-FCC (L15)', value: 'L15' },
        { text: 'Tax (L16)', value: 'L16' },
        { text: 'MPF (L17)', value: 'L17' },
        { text: 'Fee-Other (L18)', value: 'L18' },
        { text: 'Manufacturer (L19)', value: 'L19' },
        { text: 'Foreign Exporter (L20)', value: 'L20' },
        { text: 'Sold to Party (L21)', value: 'L21' },
        { text: 'Delivered To Party (L22)', value: 'L22' },
        { text: 'Textile Category (L23)', value: 'L23' },
        { text: 'Standard Visa (L24)', value: 'L24' },
        { text: 'Textile Export Date. (L25)', value: 'L25' },
        { text: 'License/Certificate/Permit (L26)', value: 'L26' },
        { text: 'Softwood Lumber Export Price (L27)', value: 'L27' },
        { text: 'Softwood Lumber Export Charges (L28)', value: 'L28' },
        { text: 'Quantity Change (L29)', value: 'L29' },
        { text: 'Relationship Change (L30)', value: 'L30' },
        { text: 'Shipping Weight (L31)', value: 'L31' },
      ],
      CountryOptions: [],
      ForeignPortsOptions: [],
      UOMOptions: [],
      line: {
        InvoiceNumber: '',
        InvRangeB: '',
        InvRangeE: '',
        PartNumber: '',
        Description: '',
        COO: '',
        COE: '',
        POL: '',
        MID: '',
        ManufactureRelated: false,
        RulingType: '',
        RulingNumber: '',
        SpecialProgram: '',
        LicenseType: '',
        License: '',
        GrossWeight: 0.00,
        ChargesAmount: 0.00,
        FTZStatus: '',
        FTZDate: '',
        FTZQty: 0.00,
        ADCaseNumber: '',
        ADBondOrCash: '',
        ADDepositRate: 0.00,
        ADRateType: '',
        ADQty: 0.00,
        ADDuty: 0.00,
        ADAmount: 0,
        ADNonReimburseDecl: '',
        CVDCaseNumber: '',
        CVDBondOrCash: '',
        CVDDepositRate: 0.00,
        CVDRateType: '',
        CVDQty: 0.00,
        CVDDuty: 0.00,
        CVDAmount: 0,
        CVDNonReimburseDecl: '',
        ADCVD: '',
        VISANumber: '',
        TaxCode: '',
        TaxAmount: 0.00,
        OtherRevCode: '',
        OtherRevAmount: 0.00,
        MPF: 0.00,
        HMF: 0.00,
        OtherFeeCode: '',
        OtherFee: 0.00,
        OtherFeeCode1: '',
        OtherFee1: 0.00,
        OtherFeeCode2: '',
        OtherFee2: 0.00,
        PSC1: '',
        PSC2: '',
        PSC3: '',
        PSC4: '',
        PSC5: '',
        CWConditionCode: '',
        CWOverride: '',
        CWConditionCode1: '',
        CWOverride1: '',
        CWConditionCode2: '',
        CWOverride2: '',
        CWConditionCode3: '',
        CWOverride3: '',
        CWConditionCode4: '',
        CWOverride4: '',
        ArticleSet: '',
        IsNAFTANetCost: false,
        ProductClaimCode: '',
        TextileCateCode: '',
        LumberExport: false,
        MeetLumberAct: false,
        LumberExportPrice: 0,
        LumberExportCharge: 0,
        SteelProduct: false,
        SteelExclusion: '',
        AluminumProduct: false,
        AluminumExclusion: '',
        KRSteelCert: false,
        KOSANumber: '',
      },
      tariff: {
        Id: '',
        HTSNumber: '',
        Qty: 0.00,
        UOM: '',
        Qty1: 0.00,
        UOM1: '',
        Qty2: 0.00,
        UOM2: '',
        Duty: 0.00,
        NDC: 0.00,
        MMV: 0.00,
        Value: 0.00,
        EnteredValue: 0.00,
      },
      CWResponse: [],
      fields: [
        { key: 'cwCode', lable: 'Warning Code' },
        { key: 'cwOverrideCode', lable: 'Override Code' },
        { key: 'cwCondition', lable: 'Condition' },
        { key: 'narrativeText', lable: 'Narrative Text' },
      ],
    }
  },
  computed: {
    totalDuty() {
      return parseFloat(this.line.Duty) + parseFloat(this.line.ADDuty) + parseFloat(this.line.CVDDuty)
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ESTariffDetail')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'HTS #', field: 'htsNumber', minWidth: 100 },
      { headerName: 'EnteredValue', field: 'enteredValue', minWidth: 100 },
      { headerName: 'Duty', field: 'duty', minWidth: 100 },
      { headerName: 'Qty', field: 'qty', minWidth: 100 },
      { headerName: 'UOM', field: 'uom', minWidth: 100 },
      { headerName: 'Qty1', field: 'qty1', minWidth: 100 },
      { headerName: 'UOM1', field: 'uom1', minWidth: 100 },
      { headerName: 'Qty2', field: 'qty2', minWidth: 100 },
      { headerName: 'UOM2', field: 'uom2', minWidth: 100 },
    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.TariffGrid.saveState('ESTariffDetail') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.TariffGrid.saveState('ESTariffDetail') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.TariffGrid.saveState('ESTariffDetail') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.TariffGrid.saveState('ESTariffDetail') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(tariffid) {
      this.$refs.ViewTariff.show()
      Object.assign(this.$data.tariff, this.$options.data.apply(this))
      this.LoadTariff(tariffid)
    },
    DoubleClickOpenPopup(params) {
      this.$refs.ViewTariff.show()
      Object.assign(this.$data.tariff, this.$options.data.apply(this))
      this.LoadTariff(params.data.id)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadTariffs(), this.LoadCountryList(), this.LoadForeignPorts(), this.LoadUOM(), this.LoadCWResponse()])
        .then(() => {
          this.LoadLine()
          this.LoadingEnd()
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadForeignPorts() {
      return axios.get('/abi/codes/ports/foreign')
        .then(rowData => {
          this.ForeignPortsOptions = rowData.data
        })
    },
    LoadUOM() {
      return axios.get('/abi/codes/uom')
        .then(rowData => {
          this.UOMOptions = rowData.data
        })
    },
    LoadTariffs() {
      return axios.get(`/entry/${this.esid}/summary/lines/line/${this.lineid}/tariff`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.TariffGrid.ResetColumns()
        })
    },
    LoadLine() {
      axios.get(`/entry/${this.esid}/summary/lines/line/${this.lineid}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response[0]
        this.TempLineId = this.lineid
        this.lineIdentifier = respdata.lineIdentifier
        this.line.InvoiceNumber = respdata.invoiceNumber
        this.line.InvRangeB = respdata.invRangeB
        this.line.InvRangeE = respdata.invRangeE
        this.line.PartNumber = respdata.partNumber
        this.line.Description = respdata.description
        this.line.COO = this.CountryOptions.find(option => option.countryCode === respdata.coo)
        this.line.COE = this.CountryOptions.find(option => option.countryCode === respdata.coe)
        this.line.POL = this.ForeignPortsOptions.find(option => option.foreignPOL === respdata.pol)
        this.line.MID = respdata.mid
        this.line.ManufactureRelated = respdata.manufactureRelated
        this.line.RulingType = this.RulingOptions.find(option => option.value === respdata.rulingType)
        this.line.RulingNumber = respdata.rulingNumber
        this.line.SpecialProgram = this.SPIOptions.find(option => option.value === respdata.specialProgram)
        this.line.LicenseType = this.LicensesOptions.find(option => option.value === respdata.licenseType)
        this.line.License = respdata.license
        this.line.GrossWeight = respdata.grossWeight
        this.line.ChargesAmount = respdata.chargesAmount
        this.line.FTZStatus = this.FTZOptions.find(option => option.value === respdata.ftzStatus)
        this.line.FTZDate = new Date(respdata.ftzDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.ftzDate) : null
        this.line.FTZQty = respdata.ftzQty
        this.line.ADCaseNumber = respdata.adCaseNumber
        this.line.ADBondOrCash = this.BondCaseOptions.find(option => option.value === respdata.adBondOrCash)
        this.line.ADDepositRate = respdata.adDepositRate
        this.line.ADRateType = this.RateOptions.find(option => option.value === respdata.adRateType)
        this.line.ADQty = respdata.adQty
        this.line.ADDuty = respdata.adDuty
        this.line.ADAmount = respdata.adAmount
        this.line.ADNonReimburseDecl = respdata.adNonReimburseDecl
        this.line.CVDCaseNumber = respdata.cvdCaseNumber
        this.line.CVDBondOrCash = this.BondCaseOptions.find(option => option.value === respdata.cvdBondOrCash)
        this.line.CVDDepositRate = respdata.cvdDepositRate
        this.line.CVDRateType = this.RateOptions.find(option => option.value === respdata.cvdRateType)
        this.line.CVDQty = respdata.cvdQty
        this.line.CVDDuty = respdata.cvdDuty
        this.line.CVDAmount = respdata.cvdAmount
        this.line.CVDNonReimburseDecl = respdata.cvdNonReimburseDecl
        this.line.VISANumber = respdata.visaNumber
        this.line.TaxCode = this.TaxOptions.find(option => option.value === respdata.taxCode)
        this.line.TaxAmount = respdata.taxAmount
        this.line.OtherRevCode = this.OtherFeesOptions.find(option => option.value === respdata.otherRevCode)
        this.line.OtherRevAmount = respdata.otherRevAmount
        this.line.MPF = respdata.mpf
        this.line.HMF = respdata.hmf
        this.line.OtherFeeCode = this.LineUserFeeOptions.find(option => option.value === respdata.otherFeeCode)
        this.line.OtherFee = respdata.otherFee
        this.line.OtherFeeCode1 = this.LineUserFeeOptions.find(option => option.value === respdata.otherFeeCode1)
        this.line.OtherFee1 = respdata.otherFee1
        this.line.OtherFeeCode2 = this.LineUserFeeOptions.find(option => option.value === respdata.otherFeeCode2)
        this.line.OtherFee2 = respdata.otherFee2
        this.line.PSC1 = this.PSCLineOptions.find(option => option.value === respdata.psC1)
        this.line.PSC2 = this.PSCLineOptions.find(option => option.value === respdata.psC2)
        this.line.PSC3 = this.PSCLineOptions.find(option => option.value === respdata.psC3)
        this.line.PSC4 = this.PSCLineOptions.find(option => option.value === respdata.psC4)
        this.line.PSC5 = this.PSCLineOptions.find(option => option.value === respdata.psC5)
        this.line.CWConditionCode = this.CWOptions.find(option => option.value === respdata.cwConditionCode)
        this.line.CWOverride = this.CWOverrideOptions.find(option => option.value === respdata.cwOverride)
        this.line.CWConditionCode1 = this.CWOptions.find(option => option.value === respdata.cwConditionCode1)
        this.line.CWOverride1 = this.CWOverrideOptions.find(option => option.value === respdata.cwOverride1)
        this.line.CWConditionCode2 = this.CWOptions.find(option => option.value === respdata.cwConditionCode2)
        this.line.CWOverride2 = this.CWOverrideOptions.find(option => option.value === respdata.cwOverride2)
        this.line.CWConditionCode3 = this.CWOptions.find(option => option.value === respdata.cwConditionCode3)
        this.line.CWOverride3 = this.CWOverrideOptions.find(option => option.value === respdata.cwOverride4)
        this.line.CWConditionCode4 = this.CWOptions.find(option => option.value === respdata.cwConditionCode4)
        this.line.CWOverride4 = this.CWOverrideOptions.find(option => option.value === respdata.cwOverride4)
        this.line.ArticleSet = this.ArticleSetOptions.find(option => option.value === respdata.articleSet)
        this.line.IsNAFTANetCost = respdata.isNAFTANetCost
        this.line.ProductClaimCode = this.ProductClaimOptions.find(option => option.value === respdata.productClaimCode)
        this.line.TextileCateCode = respdata.textileCateCode
        this.line.LumberExport = respdata.lumberExport
        this.line.MeetLumberAct = respdata.meetLumberAct
        this.line.LumberExportPrice = respdata.lumberExportPrice
        this.line.LumberExportCharge = respdata.lumberExportCharge
        this.line.SteelProduct = respdata.steelProduct
        this.line.SteelExclusion = respdata.steelExclusion
        this.line.AluminumProduct = respdata.aluminumProduct
        this.line.AluminumExclusion = respdata.aluminumExclusion
        this.line.KRSteelCert = respdata.krSteelCert
        this.line.KOSANumber = respdata.kosaNumber
        this.LoadingEnd()
      })
    },
    SendCWOverride() {
      this.Lineupdate().then(() => {
        if (this.CheckNull(this.line.CWConditionCode) && this.CheckNull(this.line.CWOverride)) {
          this.$swal({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Send it!',
          }).then(result => {
            if (result.isConfirmed) {
              this.LoadingStart()
              axios.post(`/entry/${this.esid}/summary/lines/line/${this.lineid}/censuswarning/send`).then(() => {
                this.LoadingEnd()
                this.DisplaySuccess('Census Warning Override has been Sent.')
              }).catch(e => {
                this.LoadingEnd()
                this.DisplayError('Cannot send census override.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
              })
            }
          })
        } else {
          this.DisplayError('Census Warning Override cannot be send.', 'Please check all the required elements.')
        }
      })
    },
    UpdateLine() {
      return this.$refs.headerRules.validate().then(success => {
        if (success) {
          this.$refs.adcvdRules.validate().then(success3 => {
            if (success3) {
              this.$refs.feesRules.validate().then(success4 => {
                if (success4) {
                  this.$refs.addldeclRules.validate().then(success5 => {
                    if (success5) {
                      this.LoadingStart()
                      this.Lineupdate().then(() => {
                        this.LoadingEnd()
                        this.DisplaySuccess('Line data has been saved.')
                      })
                        .catch(e => {
                          this.LoadingEnd()
                          this.DisplayError('New Line cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
                        })
                    } else {
                      this.$refs.AddlDeclTab.activate()
                    }
                  })
                } else {
                  this.$refs.FeesTab.activate()
                }
              })
            } else {
              this.$refs.ADCVDTab.activate()
            }
          })
        } else {
          this.$refs.HeaderTab.activate()
        }
      })
    },
    Lineupdate() {
      const ESLineModel = {
        InvoiceNumber: this.line.InvoiceNumber,
        InvRangeB: this.line.InvRangeB,
        InvRangeE: this.line.InvRangeE,
        PartNumber: this.line.PartNumber,
        Description: this.line.Description,
        COO: this.line.COO.countryCode,
        COE: this.line.COE.countryCode,
        POL: this.CheckNull(this.line.POL) ? this.line.POL.foreignPOL : '',
        MID: this.line.MID,
        ManufactureRelated: this.line.ManufactureRelated,
        RulingType: this.CheckNull(this.line.RulingType) ? this.line.RulingType.value : '',
        RulingNumber: this.line.RulingNumber,
        SpecialProgram: this.CheckNull(this.line.SpecialProgram) ? this.line.SpecialProgram.value : '',
        LicenseType: this.CheckNull(this.line.LicenseType) ? this.line.LicenseType.value : '',
        License: this.line.License,
        GrossWeight: this.line.GrossWeight,
        ChargesAmount: this.line.ChargesAmount,
        FTZStatus: this.CheckNull(this.line.FTZStatus) ? this.line.FTZStatus.value : '',
        FTZDate: new Date(this.line.FTZDate).toJSON().split('T')[0] !== '1970-01-01' && new Date(this.line.FTZDate).toJSON().split('T')[0] !== 'Invalid Date' ? new Date(this.line.FTZDate).toJSON().split('T')[0] : null,
        FTZQty: this.CheckNull(this.line.FTZQty) ? this.line.FTZQty : 0,
        ADCaseNumber: this.line.ADCaseNumber,
        ADBondOrCash: this.CheckNull(this.line.ADBondOrCash) ? this.line.ADBondOrCash.value : '',
        ADDepositRate: this.CheckNull(this.line.ADDepositRate) ? this.line.ADDepositRate : 0,
        ADRateType: this.CheckNull(this.line.ADRateType) ? this.line.ADRateType.value : '',
        ADQty: this.CheckNull(this.line.ADQty) ? this.line.ADQty : 0,
        ADDuty: this.CheckNull(this.line.ADDuty) ? this.line.ADDuty : 0,
        ADAmount: this.CheckNull(this.line.ADAmount) ? this.line.ADAmount : 0,
        ADNonReimburseDecl: this.line.ADNonReimburseDecl,
        CVDCaseNumber: this.line.CVDCaseNumber,
        CVDBondOrCash: this.CheckNull(this.line.CVDBondOrCash) ? this.line.CVDBondOrCash.value : '',
        CVDDepositRate: this.CheckNull(this.line.CVDDepositRate) ? this.line.CVDDepositRate : 0,
        CVDRateType: this.CheckNull(this.line.CVDRateType) ? this.line.CVDRateType.value : '',
        CVDQty: this.CheckNull(this.line.CVDQty) ? this.line.CVDQty : 0,
        CVDDuty: this.CheckNull(this.line.CVDDuty) ? this.line.CVDDuty : 0,
        CVDAmount: this.CheckNull(this.line.CVDAmount) ? this.line.CVDAmount : 0,
        CVDNonReimburseDecl: this.line.CVDNonReimburseDecl,
        ADCVD: '',
        VISANumber: this.line.VISANumber,
        TaxCode: this.CheckNull(this.line.TaxCode) ? this.line.TaxCode.value : '',
        TaxAmount: this.line.TaxAmount,
        OtherRevCode: this.CheckNull(this.line.OtherRevCode) ? this.line.OtherRevCode.value : '',
        OtherRevAmount: this.line.OtherRevAmount,
        MPF: this.line.MPF,
        HMF: this.line.HMF,
        OtherFeeCode: this.CheckNull(this.line.OtherFeeCode) ? this.line.OtherFeeCode.value : '',
        OtherFee: this.line.OtherFee,
        OtherFeeCode1: this.CheckNull(this.line.OtherFeeCode1) ? this.line.OtherFeeCode1.value : '',
        OtherFee1: this.line.OtherFee1,
        OtherFeeCode2: this.CheckNull(this.line.OtherFeeCode2) ? this.line.OtherFeeCode2.value : '',
        OtherFee2: this.line.OtherFee2,
        PSC1: this.CheckNull(this.line.PSC1) ? this.line.PSC1.value : '',
        PSC2: this.CheckNull(this.line.PSC2) ? this.line.PSC2.value : '',
        PSC3: this.CheckNull(this.line.PSC3) ? this.line.PSC3.value : '',
        PSC4: this.CheckNull(this.line.PSC4) ? this.line.PSC4.value : '',
        PSC5: this.CheckNull(this.line.PSC5) ? this.line.PSC5.value : '',
        CWConditionCode: this.CheckNull(this.line.CWConditionCode) ? this.line.CWConditionCode.value : '',
        CWOverride: this.CheckNull(this.line.CWOverride) ? this.line.CWOverride.value : '',
        CWConditionCode1: this.CheckNull(this.line.CWConditionCode1) ? this.line.CWConditionCode1.value : '',
        CWOverride1: this.CheckNull(this.line.CWOverride1) ? this.line.CWOverride1.value : '',
        CWConditionCode2: this.CheckNull(this.line.CWConditionCode2) ? this.line.CWConditionCode2.value : '',
        CWOverride2: this.CheckNull(this.line.CWOverride2) ? this.line.CWOverride2.value : '',
        CWConditionCode3: this.CheckNull(this.line.CWConditionCode3) ? this.line.CWConditionCode3.value : '',
        CWOverride3: this.CheckNull(this.line.CWOverride3) ? this.line.CWOverride3.value : '',
        CWConditionCode4: this.CheckNull(this.line.CWConditionCode4) ? this.line.CWConditionCode4.value : '',
        CWOverride4: this.CheckNull(this.line.CWOverride4) ? this.line.CWOverride4.value : '',
        ArticleSet: this.CheckNull(this.line.ArticleSet) ? this.line.ArticleSet.value : '',
        IsNAFTANetCost: this.line.IsNAFTANetCost,
        ProductClaimCode: this.CheckNull(this.line.ProductClaimCode) ? this.line.ProductClaimCode.value : '',
        TextileCateCode: this.line.TextileCateCode,
        LumberExport: this.line.LumberExport,
        MeetLumberAct: this.line.MeetLumberAct,
        LumberExportPrice: this.CheckNull(this.line.LumberExportPrice) ? this.line.LumberExportPrice : 0,
        LumberExportCharge: this.CheckNull(this.line.LumberExportCharge) ? this.line.LumberExportCharge : 0,
        SteelProduct: this.line.SteelProduct,
        SteelExclusion: this.line.SteelExclusion,
        AluminumProduct: this.line.AluminumProduct,
        AluminumExclusion: this.line.AluminumExclusion,
        KRSteelCert: this.line.KRSteelCert,
        KOSANumber: this.line.KOSANumber,

      }
      return axios.put(`/entry/${this.esid}/summary/lines/line/${this.TempLineId}`, ESLineModel)
    },
    LoadTariff(tariffid) {
      axios.get(`/entry/${this.esid}/summary/lines/line/${this.lineid}/tariff/${tariffid}`).then(resp => {
        this.LoadingStart()
        const response = resp.data
        const respdata = response[0]
        this.TempTariffId = tariffid
        this.tariff.HTSNumber = respdata.htsNumber
        this.tariff.Qty = respdata.qty
        this.tariff.UOM = this.UOMOptions.find(option => option.code === respdata.uom)
        this.tariff.Qty1 = respdata.qty1
        this.tariff.UOM1 = this.UOMOptions.find(option => option.code === respdata.uoM1)
        this.tariff.Qty2 = respdata.qty2
        this.tariff.UOM2 = this.UOMOptions.find(option => option.code === respdata.uoM2)
        this.tariff.Duty = respdata.duty
        this.tariff.NDC = respdata.ndc
        this.tariff.MMV = respdata.mmv
        this.tariff.Value = respdata.value
        this.tariff.EnteredValue = respdata.enteredValue
        this.LoadingEnd()
      })
    },
    CreateNewTariff() {
      Object.assign(this.$data.tariff, this.$options.data.apply(this))
      this.$refs.CreateTariff.show()
    },
    SaveNewTariff() {
      this.$refs.tariffRules.validate().then(success => {
        this.LoadingStart()
        if (success) {
          const ESLineTariffModel = {
            LineId: this.lineid,
            HTSNumber: this.tariff.HTSNumber,
            Qty: this.CheckNull(this.tariff.Qty) ? this.tariff.Qty : 0,
            UOM: this.tariff.UOM.code,
            Qty1: this.CheckNull(this.tariff.Qty1) ? this.tariff.Qty1 : 0,
            UOM1: this.CheckNull(this.tariff.UOM1) ? this.tariff.UOM1.code : '',
            Qty2: this.CheckNull(this.tariff.Qty2) ? this.tariff.Qty2 : 0,
            UOM2: this.CheckNull(this.tariff.UOM2) ? this.tariff.UOM2.code : '',
            Duty: this.CheckNull(this.tariff.Duty) ? this.tariff.Duty : 0,
            NDC: this.CheckNull(this.tariff.NDC) ? this.tariff.NDC : 0,
            MMV: this.CheckNull(this.tariff.MMV) ? this.tariff.MMV : 0,
            Value: this.CheckNull(this.tariff.Value) ? this.tariff.Value : 0,
            EnteredValue: this.CheckNull(this.tariff.EnteredValue) ? this.tariff.EnteredValue : 0,
          }
          axios.post(`/entry/${this.esid}/summary/lines/line/${this.lineid}/tariff/`, ESLineTariffModel).then(() => {
            this.LoadingEnd()
            this.LoadTariffs()
            this.$refs.CreateTariff.hide()
            this.DisplaySuccess('Tariff data has been saved.')
          })
            .catch(e => {
              this.LoadingEnd()
              this.LoadTariffs()
              this.$refs.CreateTariff.hide()
              this.DisplayError('Tariff cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New Tariff cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateTariff() {
      this.$refs.tariffRules.validate().then(success => {
        this.LoadingStart()
        if (success) {
          const ESLineTariffModel = {
            HTSNumber: this.tariff.HTSNumber,
            Qty: this.CheckNull(this.tariff.Qty) ? this.tariff.Qty : 0,
            UOM: this.CheckNull(this.tariff.UOM) ? this.tariff.UOM.code : '',
            Qty1: this.CheckNull(this.tariff.Qty1) ? this.tariff.Qty1 : 0,
            UOM1: this.CheckNull(this.tariff.UOM1) ? this.tariff.UOM1.code : '',
            Qty2: this.CheckNull(this.tariff.Qty2) ? this.tariff.Qty2 : 0,
            UOM2: this.CheckNull(this.tariff.UOM2) ? this.tariff.UOM2.code : '',
            Duty: this.CheckNull(this.tariff.Duty) ? this.tariff.Duty : 0,
            NDC: this.CheckNull(this.tariff.NDC) ? this.tariff.NDC : 0,
            MMV: this.CheckNull(this.tariff.MMV) ? this.tariff.MMV : 0,
            Value: this.CheckNull(this.tariff.Value) ? this.tariff.Value : 0,
            EnteredValue: this.CheckNull(this.tariff.EnteredValue) ? this.tariff.EnteredValue : 0,
          }
          axios.put(`/entry/${this.esid}/summary/lines/line/${this.lineid}/tariff/${this.TempTariffId}`, ESLineTariffModel).then(() => {
            this.LoadingEnd()
            this.LoadTariffs()
            this.$refs.ViewTariff.hide()
            this.DisplaySuccess('Tariff data has been saved.')
          })
            .catch(e => {
              this.LoadingEnd()
              this.LoadTariffs()
              this.$refs.ViewTariff.hide()
              this.DisplayError('Tariff cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.LoadingEnd()
          this.DisplayError('Tariff cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    Delete(tariffid) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/entry/${this.esid}/summary/lines/line/${this.lineid}/tariff/${tariffid}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Tariff has been removed.')
              .then(() => {
                // redirect
                this.LoadTariffs()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove Tariff.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    LoadCWResponse() {
      return axios.get(`/entry/${this.esid}/summary/lines/line/${this.lineIdentifier}/censuswarning/response`)
        .then(rowData => {
          this.CWResponse = rowData.data
        })
    },
  },

}
</script>
